import { IResultsService } from '../interfaces/results.interface';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Bar } from './models/bar.model';
import { BarInfo } from '../map/components/bar-info-modal/models/bar-info.model';

@Injectable()
export class ResultsService implements IResultsService {

    private requestCache = window.localStorage;
    private barLatLong: {lat: number, lon: number};
    private bar: Bar;

    constructor(
        public http: HttpClient
    ) {}

    getBarLatLong(): {lat: number, lon: number} {
        const barLatLong = JSON.parse(this.requestCache.getItem("currentBarLatLong"));
        return barLatLong;
    }

    getBarObject(): Bar {
        const bar = JSON.parse(this.requestCache.getItem("currentBar"));
        return bar;
    }

    setBarLatLong(location: {lat: number, lon: number}) {
        this.barLatLong = location;
        this.requestCache.removeItem("currentBarLatLong");
        this.requestCache.setItem("currentBarLatLong", JSON.stringify(this.barLatLong));
    }

    setBarObject(bar: Bar): void {
        this.bar = bar;
        this.requestCache.removeItem("currentBar");
        this.requestCache.setItem("currentBar", JSON.stringify(this.bar));
    }

    convertBarInfoToBar(barInfo: BarInfo): Bar {
        const bar = new Bar();
        bar.yelpId = barInfo.yelpId;
        bar.name = barInfo.name;
        bar.image = barInfo.image;
        bar.rating = barInfo.rating;
        bar.price = barInfo.price;
        bar.distance = barInfo.distance;
        bar.location = barInfo.location;
        bar.categories = barInfo.barCategories;
        bar.barCategory = bar.categories[0];
        return bar;
    }

    // This function gives you the list of bars closest to the one you selected.
    getCrawlResults(location: {lat: number, lon: number}): Observable<any> {
        return new Observable(s => {
            // let params = new HttpParams();
            // params = params.append('lat', location.lat.toString());
            // params = params.append('lon', location.lon.toString());
            // this.http.get('http://localhost:8080/results', { params: params }).subscribe(r => {
            //     s.next(r);      
            // });
            const params = {
                lat: location.lat.toString(),
                long: location.lon.toString(),
            }
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'text/plain'
                })
            };
            this.http.post('https://wondrous-croquembouche-d49267.netlify.app/.netlify/functions/results', 
            JSON.stringify(params),
            httpOptions).subscribe(r => {
                s.next(r);      
            });
        });
    }
}

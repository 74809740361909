import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { MetersToFeetPipe } from './pipes/meters-to-feet.pipe';
import { MetersToMilesPipe } from './pipes/meters-to-miles.pipe';

@NgModule({
  declarations:[MetersToFeetPipe, MetersToMilesPipe],
  imports:[CommonModule],
  exports:[MetersToFeetPipe, MetersToMilesPipe]
})

export class MainPipe{}
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'nearby',
    pathMatch: 'full'
  },
  {
    path: 'crawl',
    loadChildren: () => import('./crawl/crawl.module').then( m => m.CrawlPageModule)
  },
  {
    path: 'nearby',
    loadChildren: () => import('./nearby/nearby.module').then(m => m.NearbyPageModule)
  },
  {
    path: 'city',
    loadChildren: () => import('./city/city.module').then( m => m.CityPageModule)
  },
  {
    path: 'bar',
    loadChildren: () => import('./bar/bar.module').then( m => m.BarPageModule)
  },
  {
    path: 'best-city-bars',
    loadChildren: () => import('./best-city-bars/best-city-bars.module').then( m => m.BestCityBarsPageModule)
  },
  {
    path: 'bar-lookup',
    loadChildren: () => import('./bar-lookup/bar-lookup.module').then( m => m.BarLookupPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Nearby',
      url: '/crawl/nearby',
      icon: 'locate'
    },
    {
      title: 'In a City',
      url: '/crawl/city',
      icon: 'business'
    },
    {
      title: 'From a Bar',
      url: '/crawl/bar',
      icon: 'beer'
    },
    // Commenting this out for now until it gets built out.
    // {
    //   title: 'My Crawls',
    //   url: '/crawl/',
    //   icon: 'folder'
    // },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  public isIOS = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
    this.isIOS = this.platform.is("ios")
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('crawl/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  private getYelpLogo(): string {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const baseUrl = 'assets/yelp_logos/';
    const logo = prefersDark ? 'yelp_logo_dark_bg.png' : 'yelp_logo.png';
    const url = `${baseUrl}${logo}`;
    return url;
  }
}

export class Bar {
    yelpId: string;
    name: string;
    image: string;
    rating: string;
    price: string;
    distance: string;
    location: {lat: number, lon: number};
    categories: string[];
    address: string;
    barCategory: string;

    constructor(data?){
        if(data == null) return;

        this.yelpId = (data.yelpId == null) ? "" : data.yelpId;
        this.name = (data.name == null) ? "" : data.name;
        this.image = (data.image == null) ? "" : data.image;
        this.rating = (data.rating == null) ? "" : data.rating;
        this.price = (data.price == null || !data.price.length) ? "$$" : data.price;
        this.distance = (data.distance == null) ? "" : data.distance;
        this.location = (data.location  == null) ? null : data.location;
        this.categories = (data.categories == null) ? [] : data.categories;
        this.address = (data.address == null) ? "" : data.address;
        this.barCategory  = (data.barCategory == null) ? '' : data.barCategory;
    }
    
}